import {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {connect} from "react-redux";
import GridElement from "../../Common/GidElement";
import {cloneDeep} from "lodash";
import {Autocomplete, Grid, IconButton, TextField as MaterialTextField, Tooltip} from "@mui/material";
import {Delete, Edit, Email, InfoSharp, PictureAsPdf} from "@mui/icons-material";
import {BASE_URL, ECONOMIC_AGREEMENT_TOKEN, ECONOMIC_APP_SECRET, fetchGet} from "../../../API/util";
import {selectors as accountSelectors} from "../../../Ducks/account";
import {selectors as usersSelectors, actions as usersActions} from "../../../Ducks/users";
import {bindActionCreators} from "redux";
import TeamsDropDowns from "../CaseOverview/Widgets/TeamsDropDowns";
import EmailModal from "../../Common/Modals/EmailModal";
import {
    INVOICE_STATUS_ARCHIVED_OR_INVOICED,
    INVOICE_STATUS_DRAFT,
    INVOICE_STATUS_PENDING, INVOICE_STATUS_SENT,
    INVOICE_TYPE_ORDER, getEconomicDocumentSentUrl,
    getEconomicDocumentDraftUrl
} from "../../Utils/constants";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as React from "react";
import Swal from "sweetalert2";
import {deleteInvoice} from "../../../API/invoice";

toast.configure();

const makeHeader = (title) => {
    let _title = "";
    if (title.includes(".")) {
        _title = title.split(".")[1].split("_");
    } else {
        _title = title.split("_");
    }

    return _title.join(" ");
};

const makeAccessor = (key) => {
    if (key.includes(".")) {
        return key.split(".")[1];
    } else {
        return key;
    }
};

const InvoiceGrid = (props) => {
    const {navigate, team, teamDetails, canViewTeamStates, getTeamDetails, resetTeamDetails, canDeleteInvoice} = props;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [rowsCount, setRowsCount] = useState(0);
    const [filters, setFilters] = useState([]);
    const [controlledPageIndex, setControlledPageIndex] = useState(0);
    const [teams, setTeams] = useState([]);
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [documentDraft, setDocumentDraft] = useState(null);
    const [documentDraftName, setDocumentDraftName] = useState("");
    const [documentDraftBlob, setDocumentDraftBlob] = useState("");
    const [documentDraftNumber, setDocumentDraftNumber] = useState("");
    const [isDraftLoading, setIsDraftLoading] = useState(false);
    const [isDraftSending, setIsDraftSending] = useState(false);
    const [refreshGridData, setRefreshGridData] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);

    const [cols, _setCols] = useState([
        {
            column: "persons.person_firstname",
            accessor: makeAccessor("persons.person_firstname"),
            minWidth: 150,
            Header: makeHeader("persons.person_firstname"),
            filter: "fuzzyText",
        },
        {
            column: "persons.person_lastname",
            accessor: makeAccessor("persons.person_lastname"),
            minWidth: 150,
            Header: makeHeader("persons.person_lastname"),
            filter: "fuzzyText",
        },
        {
            column: "invoices.invoice_number",
            accessor: makeAccessor("invoices.invoice_number"),
            minWidth: 150,
            Header: "Draft/Invoice Number",
            filter: "fuzzyText",
            Cell: ({value, row}) => (
                <a href={`${window.location.origin}/finance/id/${row.original.invoice_id}`} rel='noopener noreferrer'>
                    {value}
                </a>
            ),
        },
        {
            column: "opportunities.opportunity_cvr",
            accessor: makeAccessor("opportunities.opportunity_cvr"),
            minWidth: 150,
            Header: "CVR",
            filter: "fuzzyText",
        },
        {
            column: "purchases.purchase_id",
            accessor: makeAccessor("purchases.purchase_id"),
            minWidth: 150,
            Header: makeHeader("purchases.purchase_id"),
            filter: "fuzzyText",
        },
        {
            column: "products.product_name",
            accessor: makeAccessor("products.product_name"),
            minWidth: 200,
            Header: makeHeader("products.product_name"),
            filter: "fuzzyText",
        },
        {
            column: "invoices.document_type",
            accessor: makeAccessor("document_type"),
            minWidth: 150,
            Header: makeHeader("document_type"),
            filter: "fuzzyText",
        },
        {
            column: "invoice_payment_terms_type",
            accessor: makeAccessor("invoice_payment_terms_type"),
            minWidth: 150,
            Header: "Payment Terms",
            filter: "fuzzyText",
        },
        {
            column: "invoices.invoice_sent_date",
            accessor: makeAccessor("invoice_sent_date"),
            minWidth: 150,
            Header: makeHeader("invoice_date"),
            filter: "fuzzyText",
        },
        {
            column: "invoices.invoice_status",
            accessor: makeAccessor("invoice_status"),
            minWidth: 150,
            Header: () => (
                <>
                    Document Status &nbsp; <Tooltip style={{maxWidth: 600}} title={
                    <Grid container>
                        <Grid item xs={12}
                              style={{whiteSpace: 'pre-line'}}>
                            <Grid container spacing={1}>
                                <Grid item xs={3}
                                      style={{"textAlign": "right"}}><b>Pending </b></Grid>
                                <Grid item xs={9} style={{"textAlign": "left"}}> Invoices/Orders are created in CRM not
                                    in
                                    E-cononic</Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={3} style={{"textAlign": "right"}}><b>Queued </b></Grid>
                                <Grid item xs={9} style={{"textAlign": "left"}}> Invoices/Orders are queued for being
                                    drafted in
                                    E-conomic</Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={3} style={{"textAlign": "right"}}><b>Draft</b></Grid>
                                <Grid item xs={9} style={{"textAlign": "left"}}>Invoices/Orders are drafted in
                                    E-conomic</Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={3} style={{"textAlign": "right"}}><b>Sent</b></Grid>
                                <Grid item xs={9} style={{"textAlign": "left"}}>Invoices/Orders are sent to
                                    customer</Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={3} style={{"textAlign": "right"}}><b>Archived/Invoiced</b></Grid>
                                <Grid item xs={9} style={{"textAlign": "left"}}>Orders are either upgraded to invoice or
                                    archived </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                } arrow placement='top'><InfoSharp/></Tooltip>
                </>
            ),
            filter: "fuzzyText",
        },
        {
            column: "invoices.invoice_payment_status",
            accessor: makeAccessor("invoice_payment_status"),
            minWidth: 150,
            Header: makeHeader("invoice_payment_status"),
            filter: "fuzzyText",
        },
        {
            column: "invoices.invoice_payment_date",
            accessor: makeAccessor("invoice_payment_date"),
            minWidth: 150,
            Header: makeHeader("invoice_payment_date"),
            filter: "fuzzyText",
        },
        {
            column: "invoice_creation_date",
            accessor: makeAccessor("invoice_creation_date"),
            minWidth: 150,
            Header: "Created At",
            filter: "fuzzyText",
        },
        {
            column: "invoices.invoice_deadline_date",
            accessor: makeAccessor("invoice_deadline_date"),
            minWidth: 150,
            Header: "Deadline",
            filter: "fuzzyText",
        },
        {
            Header: "Action",
            filter: "fuzzyText",
            Cell: ({row}) => (
                <div>
                    {(row.original.invoice_status === INVOICE_STATUS_PENDING
                            || (row.original.document_type === INVOICE_TYPE_ORDER
                                && (row.original.invoice_status === INVOICE_STATUS_DRAFT || row.original.document_type === INVOICE_STATUS_SENT)))
                        && row.original.parent_id === 0 && (
                            <Tooltip title='Edit Document' arrow placement='top'>
                                <IconButton
                                    aria-label='edit finance'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    onClick={() => navigate(`/finance/id/${row.original.invoice_id}`)}
                                    style={{
                                        color: "#000",
                                        fontSize: "17px",
                                        fontWeight: "600",
                                    }}
                                    disabled={isDeleting}
                                >
                                    <Edit color='primary'/>
                                </IconButton>
                            </Tooltip>
                        )}
                    {row.original.invoice_number && (row.original.invoice_status !== INVOICE_STATUS_PENDING && row.original.invoice_status !== INVOICE_STATUS_ARCHIVED_OR_INVOICED)
                        && (
                            <Tooltip title='View Document' arrow placement='top'>
                                <IconButton
                                    aria-label='view document'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    onClick={() => handleViewDocumentClick(row.original)}
                                    style={{
                                        color: "#000",
                                        fontSize: "17px",
                                        fontWeight: "600",
                                    }}
                                >
                                    <PictureAsPdf color='primary'/>
                                </IconButton>
                            </Tooltip>
                        )}
                    {row.original.invoice_number &&
                        row.original.document_type === INVOICE_TYPE_ORDER &&
                        row.original.invoice_status === INVOICE_STATUS_DRAFT && (
                            <Tooltip title='Send Document' arrow placement='top'>
                                <IconButton
                                    aria-label='send document'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    onClick={() => handleSendDocumentClick(row.original)}
                                    style={{
                                        color: "#000",
                                        fontSize: "17px",
                                        fontWeight: "600",
                                    }}
                                    disabled={isDeleting}
                                >
                                    <Email color='primary'/>
                                </IconButton>
                            </Tooltip>
                        )}
                    {canDeleteInvoice && row.original.invoice_status === INVOICE_STATUS_PENDING && (
                        <Tooltip title='Delete Invoice' arrow placement='top'>
                            <IconButton aria-label='delete document'
                                        aria-controls='menu-appbar'
                                        aria-haspopup='true'
                                        onClick={() => handleDeleteInvoiceClick(row.original)}
                                        style={{
                                            color: "#FF0000",
                                            fontSize: "17px",
                                            fontWeight: "600",
                                        }}
                                        disabled={isDeleting}
                            ><Delete/></IconButton>
                        </Tooltip>
                    )}
                </div>
            ),
        },
    ]);
    const fetchIdRef = useRef(0);
    const fetchData = useCallback(({pageSize, pageIndex, sortBy, filters, columns}) => {
        //fetchData will be called whenever table needs new data.
        let _columns = columns.map((item) => item.column);
        _columns = _columns.filter((item) => item !== undefined);

        const fetchId = ++fetchIdRef.current;
        setLoading(true);

        // Check filter data
        let query = "",
            api = "",
            sort = "",
            filterPrefix = "";
        filters.forEach((item) => {
            if (item.id == "team_id" || item.id == "user_id") {
                query = query + `&equals[${item.id}]=${item.value}`;
            } else {
                const _filterColumn = columns.filter((column) => column.accessor === item.id);
                if (_filterColumn[0].column.includes(".")) {
                    filterPrefix = _filterColumn[0].column.split(".")[0];
                } else {
                    filterPrefix = "invoices";
                }

                query = query + `&contains[${filterPrefix}.${item.id}]=${item.value}`;
            }
        });

        // Check sorting
        if (sortBy.length) {
            let _sortByPrefix = "",
                _sortByColumn = columns.filter((column) => column.accessor === sortBy[0].id);
            if (_sortByColumn[0].column.includes(".")) {
                _sortByPrefix = _sortByColumn[0].column.split(".")[0];
                _sortByPrefix = `${_sortByPrefix}.`;
            }
            sort = `&sortBy=${sortBy[0].desc ? "-" : ""}${_sortByPrefix}${sortBy[0].id}`;
        }

        api = `/invoices?results=${pageSize}&columns=${_columns}&offset=${pageIndex + 1}${query}${sort}`;

        fetchGet(api)
            .then((invoices) => {
                if (fetchId == fetchIdRef.current && invoices) {
                    const _invoices = cloneDeep(invoices);

                    setData(_invoices.data.main);
                    setPageCount(invoices.data.offset.highest_offset);
                    setRowsCount(invoices.data.offset.rows_count);
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });

        setRefreshGridData(false); //if grid fetches latest data then set refreshGridData to false
    }, []);

    const resetFilters = useCallback(() => setFilters([]), [setFilters]);

    const handleViewDocumentClick = async (data) => {
        let api = '';

        if (data.invoice_status === INVOICE_STATUS_DRAFT) {
            api = getEconomicDocumentDraftUrl(data.invoice_number, data.document_type);
        } else if (data.invoice_status === INVOICE_STATUS_SENT) {
            api = getEconomicDocumentSentUrl(data.invoice_number, data.document_type);
        }

        try {
            const response = await fetch(api, {
                method: "GET",
                headers: {
                    "X-AppSecretToken": ECONOMIC_APP_SECRET,
                    "X-AgreementGrantToken": ECONOMIC_AGREEMENT_TOKEN,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const blob = await response.blob();
            if (blob.type !== "application/pdf") {
                throw new Error("Response is not a PDF");
            }

            const url = window.URL.createObjectURL(blob);
            const newTab = window.open(url, "_blank", "noopener,noreferrer");
            if (newTab) {
                newTab.onload = () => {
                    window.URL.revokeObjectURL(url);
                };
            }
        } catch (error) {
        }
    };

    const handleSendDocumentClick = async (data) => {
        let _recipients = [];

        if (data.job && data.job.job_email) {
            _recipients.push(data.job.job_email);
        }

        if (data.purchase_bookkeeper_email) {
            _recipients.push(data.purchase_bookkeeper_email);
        }

        loadTemplates();
        downloadDocumentDraft(data);
        setRecipients(_recipients);
        setOpenEmailModal(true);
    };

    const handleEmailModalClose = () => {
        setOpenEmailModal(false);
    };

    const handleDeleteInvoiceClick = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                setIsDeleting(true);
                deleteInvoice(data.invoice_id).then((res) => {
                    toast.success(res.message);
                    setRefreshGridData(true);
                    setIsDeleting(false);
                }).catch((error) => {
                    toast.error(error);
                    setIsDeleting(false);
                });
            }
        });
    }

    const downloadDocumentDraft = async (data) => {
        setIsDraftLoading(true);
        setDocumentDraftNumber(data.invoice_number);

        let api = getEconomicDocumentDraftUrl(data.invoice_number, data.document_type);

        try {
            const response = await fetch(api, {
                method: "GET",
                headers: {
                    "X-AppSecretToken": ECONOMIC_APP_SECRET,
                    "X-AgreementGrantToken": ECONOMIC_AGREEMENT_TOKEN,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const file = await response.blob();
            setDocumentDraftBlob(file);

            if (file.type !== "application/pdf") {
                throw new Error("Response is not a PDF");
            }

            const url = window.URL.createObjectURL(new Blob([file], {type: "application/pdf"}));

            const currentDate = new Date();

            const year = currentDate.getFullYear();
            const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
            const day = ("0" + currentDate.getDate()).slice(-2);
            const hours = ("0" + currentDate.getHours()).slice(-2);
            const minutes = ("0" + currentDate.getMinutes()).slice(-2);
            const seconds = ("0" + currentDate.getSeconds()).slice(-2);

            const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;

            setDocumentDraft(url);
            setDocumentDraftName(`order_${data.invoice_number}_${formattedDate}.pdf`);
            setIsDraftLoading(false);
        } catch (_error) {
        }
    };

    const sendOrderDraft = (data) => {
        setIsDraftSending(true);

        let formData = new FormData();
        formData.append("recipients", data.recipients);
        formData.append("subject", data.subject);
        formData.append("message", data.message);
        formData.append("attachment", documentDraftBlob, documentDraftName);

        let api = `/invoices/${documentDraftNumber}/email`;

        fetch(BASE_URL + api, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: formData,
        })
            .then((res) => {
                if (res.ok) {
                    toast.success("PDF is sent via email");
                }

                setIsDraftSending(false);
                handleEmailModalClose();
                setRefreshGridData(true);
            })
            .catch((error) => {
                toast.error(error.message);
                setIsDraftSending(false);
            });
    };

    const loadTemplates = () => {
        let api = `/templates/order/list`;

        fetchGet(api)
            .then((data) => {
                if (data.main.templates) {
                    setTemplates(data.main.templates);
                }
            })
            .catch(() => {
            });
    }

    useEffect(() => {
        const {getTeams} = props;
        getTeams();
    }, []);

    const filterInvoicesWithTeams = (filter) => {
        let _filters = cloneDeep(filters);
        let filterFound = false;

        _filters.forEach((item, index) => {
            if (filter) {
                if (filter.hasOwnProperty("user_id")) {
                    if (item.id === "user_id") {
                        item.value = filter.user_id;
                        filterFound = true;
                    } else if (item.id === "team_id") {
                        _filters.splice(index, 1, {
                            id: "user_id",
                            value: filter.user_id,
                        });
                        filterFound = true;
                    }
                } else if (filter.hasOwnProperty("team_id")) {
                    if (item.id === "team_id") {
                        item.value = filter.team_id;
                        filterFound = true;
                    } else if (item.id === "user_id") {
                        _filters.splice(index, 1, {
                            id: "team_id",
                            value: filter.team_id,
                        });
                        filterFound = true;
                    }
                }
            } else {
                if (item.id === "user_id" || item.id === "team_id") {
                    _filters.splice(index, 1);
                    filterFound = true;
                }
            }
        });

        if (!filterFound && filter) {
            if (filter.hasOwnProperty("team_id")) {
                _filters.push({id: "team_id", value: filter.team_id});
            } else if (filter.hasOwnProperty("user_id")) {
                _filters.push({id: "user_id", value: filter.user_id});
            }
        }

        setFilters(_filters);
    };

    useEffect(() => {
        if (team) {
            let _teams = team["rows"]?.map((item) => {
                item.key = item.key;
                item.label = item.team_name;
                return item;
            });
            setTeams(_teams);
        }
    }, [team]);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h2 className='page-title'>Finance</h2>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            item
                            xs={8}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                        >
                            {canViewTeamStates ? (
                                <Autocomplete
                                    disablePortal
                                    id='combo-box-demo'
                                    options={teams}
                                    sx={{width: 200, marginRight: "10px"}}
                                    onChange={(_event, value) => {
                                        if (value === null) {
                                            resetTeamDetails();
                                            filterInvoicesWithTeams();
                                        } else {
                                            resetTeamDetails();
                                            getTeamDetails({
                                                team_id: value?.team_id,
                                            });
                                            filterInvoicesWithTeams({
                                                team_id: value?.team_id,
                                            });
                                        }
                                    }}
                                    renderOption={(props, option, _state) => {
                                        return (
                                            <div {...props}>
                                                <div
                                                    style={{
                                                        textAlign: "left",
                                                        padding: "0",
                                                        fontSize: "13px",
                                                        borderBottom: "1px solid #eaeaea",
                                                    }}
                                                >
                                                    <span>{`${option.label}`}</span>
                                                </div>
                                            </div>
                                        );
                                    }}
                                    renderInput={(params) => <MaterialTextField {...params} label='Teams'/>}
                                />
                            ) : (
                                <></>
                            )}
                            {teamDetails.map((item, index) => (
                                <TeamsDropDowns
                                    teamDetails={item}
                                    key={index}
                                    dropDownKey={index}
                                    getTeamDetails={getTeamDetails}
                                    resetTeamDetails={resetTeamDetails}
                                    filterWithTeamsCallback={filterInvoicesWithTeams}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <GridElement
                columns={cols}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                controlledPageIndex={controlledPageIndex}
                setControlledPageIndex={setControlledPageIndex}
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                rowsCount={rowsCount}
                handleRowClick={() => {
                }}
                initialSortBy='invoice_creation_date'
                sortOrder='desc'
                hideSelection={true}
                refreshGridData={refreshGridData}
            />
            <EmailModal
                open={openEmailModal}
                handleClose={handleEmailModalClose}
                recipients={recipients}
                subject=''
                attachment={documentDraft}
                attachmentName={documentDraftName}
                submitEmailFormCallback={sendOrderDraft}
                isLoading={isDraftLoading}
                isSubmitting={isDraftSending}
                templates={templates}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    canViewTeamStates: accountSelectors.canViewTeamStates(state),
    team: usersSelectors.caseTeams(state),
    teamDetails: usersSelectors.teamDetails(state),
    canDeleteInvoice: accountSelectors.canDeleteInvoice(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getTeams: usersActions.getCaseTeams,
            getTeamDetails: usersActions.getTeamDetails,
            resetTeamDetails: usersActions.resetTeamDetails,
        },
        dispatch
    );

function WithNavigate(props) {
    let navigate = useNavigate();
    return <InvoiceGrid {...props} navigate={navigate}/>;
}

export default connect(mapStateToProps, mapDispatchToProps)(WithNavigate);
